import * as React from "react"

import Signup from "../components/signup"
import Seo from "../components/seo"
import ReduxWrapper from "../redux/reduxWrapper"
import { GoogleOAuthProvider } from "@react-oauth/google"

const SignupPage = props => {
  const params = new URLSearchParams(props.location.search)
  return (
    <React.Fragment>
      <Seo title="Signup" />
      <GoogleOAuthProvider clientId={process.env.GATSBY_GOOGLE_CLIENT_ID}>
        <Signup location={props.location} switchUserEmail={params.get("switch_user")} />
      </GoogleOAuthProvider>
    </React.Fragment>
  )
}

const WrappedPage = props => <ReduxWrapper element={<SignupPage {...props} />} />
export default WrappedPage
