import React from "react"
import "../components/login.css"
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap-icons/font/bootstrap-icons.css"
import { OverlayTrigger, Tooltip } from "react-bootstrap"

const SocialMediaLogin = ({ type, googleLogin }) => {
  return (
    <>
      <div className="signup-or-separator">
        <h1>
          <span className="signup-or-separator--text h6">1-Click {type} With...</span>
        </h1>
        <hr></hr>
      </div>
      <div className="social">
        {/* <a
          href="#"
          className="btn btn-social btn-facebook btnComingSoon"
          data-toggle="tooltip"
          data-placement="bottom"
          title="Coming soon"
        >
          <i className="bi bi-facebook" />
        </a>{" "} */}
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip>
              This app does not post publicly, unless you specifically use the tool to do so in the marketing
              section.
            </Tooltip>
          }
        >
          <a href="#" className="btn btn-social btn-google" onClick={() => googleLogin()}>
            <i className="bi bi-google" />
          </a>
        </OverlayTrigger>{" "}
        {/* {type !== "signup" && (
          <>
            <a
              className="btn btn-social btn-windows btnComingSoon"
              href="#"
              data-toggle="tooltip"
              data-placement="bottom"
              title="Coming soon"
            >
              <i className="bi bi-windows" />
            </a>{" "}
            <a
              className="btn btn-social btn-apple btnComingSoon"
              href="#"
              data-bs-toggle="tooltip"
              data-placement="bottom"
              title="Coming soon"
            >
              <i className="bi bi-apple" />
            </a>
          </>
        )} */}
      </div>
    </>
  )
}

export default SocialMediaLogin
